<script setup lang="ts">
import type { ZodSchema } from 'zod'

const model = defineModel<boolean>()
withDefaults(
  defineProps<{
    modal?: boolean
    loading?: boolean
    schema: ZodSchema
    state: object
  }>(),
  { modal: false, loading: false }
)
defineEmits(['submit', 'error'])

defineSlots<{
  default: (props: Record<string, never>) => any // eslint-disable-line @typescript-eslint/no-explicit-any, no-unused-vars
  header: (props: Record<string, never>) => any // eslint-disable-line @typescript-eslint/no-explicit-any, no-unused-vars
  footer: (props: Record<string, never>) => any // eslint-disable-line @typescript-eslint/no-explicit-any, no-unused-vars
}>()
</script>

<template>
  <UModal v-if="modal" v-model="model">
    <UForm
      :schema="schema"
      :state="state"
      @submit="$emit('submit', $event)"
      @error="$emit('error', $event)"
    >
      <fieldset :disabled="loading">
        <UCard>
          <template #header>
            <div class="flex items-center justify-between">
              <slot name="header" />
              <UButton
                color="gray"
                variant="ghost"
                icon="i-heroicons-x-mark-20-solid"
                class="-my-1"
                @click="model = false"
              />
            </div>
          </template>
          <div class="space-y-4">
            <slot />
          </div>
          <template #footer>
            <div class="flex items-center justify-end">
              <slot name="footer" />
            </div>
          </template>
        </UCard>
      </fieldset>
    </UForm>
  </UModal>
  <div v-else>
    <UForm
      :schema="schema"
      :state="state"
      @submit="$emit('submit', $event)"
      @error="$emit('error', $event)"
    >
      <fieldset class="space-y-4" :disabled="loading">
        <div class="flex items-center justify-between">
          <slot name="header" />
        </div>
        <div class="space-y-4">
          <slot />
        </div>
        <div>
          <slot name="footer" />
        </div>
      </fieldset>
    </UForm>
  </div>
</template>
